<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="완료 리스트">
            - 월별 진척률 100%인 항목이 노출됩니다.<br>
            - 불러오기 클릭 시 최근 작성한 E.R 업무 리스트에 추가할 수 있습니다.
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <div class="mt-40 mb-20">
                        <select v-model="bbs.syear" @change="bbs.doInit" class="w-100px ml-10">
                                <option :value="v" v-for="(v, k) in bbs.search_years" :key="k">{{ v }}</option>
                            </select>
                        <select v-model="bbs.smonth" @change="bbs.doInit" class="w-100px ml-10">
                            <option :value="(i>9?i:'0'+i)" v-for="i in 12" :key="i">{{ (i>9?i:'0'+i) }}</option>
                        </select>
                    </div>
                    
                    <table class="table-col">
                        <colgroup>
                            <col width="70">
                            <col width="140">
                            <col width="250">
                            <col width="80">
                            <col width="100">
                            <col width="100">
                            <col width="80">
                            <col width="80">
                            <col width="220">
                            <col width="80">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>Q영역</th>
                                <th>구분</th>
                                <th>상세</th>
                                <th>진척율</th>
                                <th>시작일</th>
                                <th>완료일</th>
                                <th>기간(일)</th>
                                <th>변경차수</th>
                                <th>Pending Matter</th>
                                <th>진행</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in bbs.list" :key="i">
                                <td>{{ irow.qtype }}</td>
                                <td>{{ irow.ctype }}</td>
                                <td class="preline font-13 txt-left">{{ irow.detail }}</td>
                                <td>100%</td>
                                <td>{{ irow.sdate }}</td>
                                <td>{{ irow.edate }}</td>
                                <td>{{ irow.period }}</td>
                                <td>{{ irow.cnt_edit }}</td>
                                <td>{{ irow.pendingmatter }}</td>
                                <td>
                                    <span @click="bbs.loadContents(irow)" class="btn-view">불러오기</span>
                                </td>
                            </tr>
                            <tr v-if="bbs.total==0">
                                <td colspan="10">완료 목록이 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging :total="bbs.total" :list_per_page="bbs.rows" v-model="bbs.page" @change="bbs.doSearch"></CarrotPaging>

                    <button @click="bbs.goList" class="btn-default float-left mt-30">목록</button>
                    <div class="clear"></div>

                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js';
import Swal from 'sweetalert2';


export default {
    layout:"BusinessManagement",
    components: {
    },
    setup() {
        const router = new useRouter();
        const route  = new useRoute();
        const toast = useToast()

        const bbs = reactive({
            is_mounted   : false,
            search_years : [],

            idx  : 0,
            page : 1,
            rows : 50,

            syear  : "",
            smonth : "",

            info : {},
            list : [], total : 0,

            goList : () => {
                router.push({
                    name : 'BusinessManagement-ERList'
                });
            },

            loadContents : (o) => {
                Swal.fire({
                    title : '완료 리스트',
                    text  : '최근 ER에 추가하시겠습니까?',
                    showCancelButton: true,
                }).then((res) => {
                    if( res.isConfirmed == true ){
                        let params = {
                            idx_hq : bbs.idx,
                            qtype  : o.qtype,
                            ctype  : o.ctype,
                            detail : o.detail,
                            state  : 'done'
                        };

                        axios.post("/rest/businessmgr/setERrestart", params).then((res) => {
                            if( res.data.err == 0 ){
                                bbs.doSearch();
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        });
                    }
                });
            },

            doInit : () => {
                bbs.page = 1;
                bbs.doSearch();
            },

            doSearch : () => {
                if( bbs.is_mounted == false ) return;
                let params = {
                    idx_hq : bbs.idx,
                    page   : bbs.page,
                    rows   : bbs.rows,
                    syear  : bbs.syear,
                    smonth : bbs.smonth
                };

                axios.get("/rest/businessmgr/getERCompleteList", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            makeSearchYears: () => {
                let cd = new Date();
                let cy = cd.getFullYear();
                for(let i=cy; i>=2020; i--){
                    bbs.search_years.push(i);
                }
            }
        });

        onMounted(() => {
            // Mounted
            let nd = new Date();
            bbs.syear  = nd.getFullYear();
            bbs.smonth = (nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1));
            bbs.makeSearchYears();

            bbs.idx = route.params.idx?route.params.idx:0;

            if ( !bbs.idx ) {
                router.back(-1);
            }

            bbs.is_mounted = true;
            bbs.doSearch();
        });

        return {bbs};
    }
}
</script>

<style lang="scss" scoped>
</style>